a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 576px) {
  html {
    font-size: 16px;
  }
}

header {
  border-bottom: solid 1px #ccc;
}

a.navbar-brand {
  /* background: url('/images/sharepark-logo.svg') no-repeat; */
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  width: 200px;
  overflow: hidden;
  padding-top: 40px;
  margin: 7px;
}

.navbar-brand > svg {
  width: 200px;
  height: 45px;
  top: 7px;
  left: 0px;
  position: absolute;
}

.sidebar a.active {
  font-weight: bold;
}

header a.active {
  font-weight: bold;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 60px 0 0;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
}

#sidebarMenu {
  position: relative;
  padding: 0 0 10px 0;
}

@media (min-width: 768px) {
  #sidebarMenu {
    position: fixed;
    padding: 60px 0 0;
  }

  #sidebarMenu.col-md-3 {
    flex: 0 0 auto;
    width: 150px;
  }
}
