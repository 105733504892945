/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary,
a.btn-primary {
  color: #fff;
  background-color: #ff9016;
  border-color: #f98200;
  text-transform: uppercase;
  font-weight: bold;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #ff9016;
  border-color: #f98200;
}

.btn-primary:hover {
  color: #fff;
  background-color: #ff9b2d;
  border-color: #ff9016;
}

label.required::after {
  content: "*";
  color: #f00;
  padding-left: 0.25em;
}

.hour-cell {
  width: 45px;
  font-size: smaller;
  cursor: pointer;
  text-align: center;
}

.hour-cell:hover {
  background-color: #ddd;
}

div.loading {
  position: absolute;
  background-color: rgb(255, 255, 255, 0.2);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  z-index: 1009;
}

.loading-spinner {
  animation: spin 0.5s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: #ff9016;
  border-top-color: #ff9016;
  box-sizing: border-box;
  height: 10vmin;
  left: calc(50% - 5vmin);
  position: relative;
  top: calc(50% - 5vmin);
  width: 10vmin;
  z-index: 1010;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
