/* 
@media (min-width: 576px) { 

    body {

    }

    header > nav.navbar {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        border-bottom: solid 1px #ddd;
    }

    #page {
        display: flex;
        height: calc( 100vh - 1px);
        padding-top: 61px;
    }

    #sidebar {
        display: flex;
        align-items: stretch;
    }

    #side-nav {
        min-width: 140px;
    }

    #content {
        background-color: #fff;
        flex: 1 0 auto;
    }
} */

main {
  display: block;
}

@media (min-width: 768px) {
  main.col-md-9 {
    width: calc(100% - 140px);
  }
}

@media (min-width: 992px) {
  main.col-lg-10 {
    width: calc(100% - 140px);
  }
}
